/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import api from '../../../api/api';
import PayPalButtons from '../../../components/PayPalButtons/PayPalButtons';
import Banner from '../../../components/Banner/Banner';
import ColorSelect from '../../../components/ColorSelect/ColorSelect';
import CrossSells from '../../../components/CrossSells/CrossSells';
import Timer from './Timer';

import {
  buildQueryParams,
  getMaxDiscount,
  getPriceWithCurrency,
  mapColorsFromProducts,
} from '../../../utils/helpers';
import { states } from '../../../utils/states';

import secureLock from './images/secure-lock.png';
import questionIcn from './images/question-icn.png';
import paymentCards from './images/payment-cards.png';
import lock2 from './images/lock2.png';
import saveCheckoutImage from './images/save-checkout-img.png';
import guaranteeSeal from './images/guarantee-seal.png';
import star from './images/star.png';
import verifyIcn from './images/verify-icn.png';

import styles from './Shopify.module.css';

function getFbcValue(queryParams) {
  if (Cookies.get('_fbc')) {
    return Cookies.get('_fbc');
  }

  if (queryParams.fbclid) {
    const timestamp = new Date().getTime();
    return `fb.1.${timestamp}.${queryParams.fbclid}`;
  }

  return '';
}

function getCustomFields() {
  const result = [];
  const fields = {
    17: queryString.parse(location.search).wbraid,
    16: queryString.parse(location.search).gbraid,
    15: queryString.parse(location.search).gclid,
  };

  Object.keys(fields).forEach((key) => {
    if (fields[key]) {
      result.push({
        id: key,
        value: fields[key],
      });
    }
  });

  return result;
}

function makePlural(str, quantity) {
  if (quantity === 1 || str.toLowerCase().endsWith('s')) {
    return str;
  } else {
    return `${str}s`;
  }
}

const validationMessages = {
  email: 'Enter valid email address.',
  phone: 'Enter valid phone number.',
  creditCardNumber: 'Enter valid credit card number.',
  firstName: 'First name is required.',
  lastName: 'Last name is required.',
  shippingAddress1: 'Shipping address is required.',
  shippingCity: 'City is required.',
  shippingState: 'This Field is required.',
  shippingZip: 'ZIP code is required.',
  expirationMonth: 'Select a valid expiration month.',
  expirationYear: 'Select a valid expiration year.',
  CVV: 'Enter valid CVV.',
  isTermsAgreed: 'You must agree to the terms.',
};

// Validate a single field
function validateField(fieldName, value, shippingCountry) {
  switch (fieldName) {
    case 'email':
      if (!value || !/\S+@\S+\.\S+/.test(value)) {
        return validationMessages.email;
      }
      break;
    case 'phone':
      if (!value) {
        return validationMessages.phone;
      }

      if (shippingCountry === 'GB') {
        // Validate UK phone numbers in international format
        if (!/^\+44\d{10}$/.test(value.replace(/\s+/g, ''))) {
          return 'Enter a valid UK phone number.';
        }
      } else if (shippingCountry === 'US' || shippingCountry === 'CA') {
        // Validate US and CA phone numbers in international format
        if (!/^\+1\d{10}$/.test(value.replace(/\s+/g, ''))) {
          return 'Enter a valid US or CA phone number.';
        }
      }
      break;
    case 'creditCardNumber':
      if (!value || !/^\d{13,19}$/.test(value.replace(/\s+/g, ''))) {
        return validationMessages.creditCardNumber;
      }
      break;
    case 'firstName':
      if (!value) {
        return validationMessages.firstName;
      }
      break;
    case 'lastName':
      if (!value) {
        return validationMessages.lastName;
      }
      break;
    case 'shippingAddress1':
      if (!value) {
        return validationMessages.shippingAddress1;
      }
      break;
    case 'shippingCity':
      if (!value) {
        return validationMessages.shippingCity;
      }
      break;
    case 'shippingState':
      if (!value) {
        return validationMessages.shippingState;
      }
      break;
    case 'shippingZip':
      if (!value) {
        return validationMessages.shippingZip;
      }
      break;
    case 'expirationMonth':
      if (!value || isNaN(value) || value < 1 || value > 12) {
        return validationMessages.expirationMonth;
      }
      break;
    case 'expirationYear':
      if (!value || isNaN(value)) {
        return validationMessages.expirationYear;
      }
      break;
    case 'CVV':
      if (!value || !/^\d{3,4}$/.test(value)) {
        return validationMessages.CVV;
      }
      break;
    case 'isTermsAgreed':
      if (!value) {
        return validationMessages.isTermsAgreed;
      }
      break;
    default:
      break;
  }

  return null; // No error
}

const validateForm = (data, shippingCountry) => {
  const errors = {};
  Object.keys(validationMessages).forEach((field) => {
    const error = validateField(field, data[field], shippingCountry);
    if (error) {
      errors[field] = error;
    }
  });

  // Additional validation for combined expiration date
  if (!errors.expirationMonth && !errors.expirationYear) {
    const expDate = moment(`${data.expirationMonth}-${data.expirationYear}`, 'MM-YYYY', true).endOf('month');
    const currentDate = moment();

    if (!expDate.isValid() || expDate.isBefore(currentDate, 'day')) {
      errors.expirationMonth = validationMessages.expirationMonth;
      errors.expirationYear = validationMessages.expirationYear;
    }
  }
  return errors;
};

export default function Shopify({ offer, isLoading, crossSells, onTermsClick, onCreateOrder, declineError }) {
  const [banners, setBanners] = useState([]);
  const queryParams = buildQueryParams(location);
  const showPayPal = queryParams.paypal === '1' || offer.showPayPal;

  const mergedProductsData = offer.productsData.map((product) => {
    const productDetail = offer.productDetails[product.id];

    return {
      ...product,
      ...productDetail,
    };
  });

  const defaultProduct = mergedProductsData.find((item) => item?.id === offer?.defaultVariantId);
  const initialProduct = defaultProduct || mergedProductsData[0];

  const defaultVariantId = offer.defaultVariantId;
  const someProductIsSubscription = mergedProductsData.some((item) => item.billingModelId !== '2');

  const [clientToken, setClientToken] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedOfferType, setSelectedOfferType] = useState('subscription');
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [reviews, setReviews] = useState([]);

  const [errors, setErrors] = useState({});

  const [state, setState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    shippingAddress1: '',
    shippingCity: '',
    shippingState: 'AL',
    shippingCountry: offer?.shippingCountries[0]?.id || 'US',
    shippingZip: '',
    phone: '',
    creditCardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    CVV: '',
    billingFirstName: '',
    billingLastName: '',
    billingAddress1: '',
    billingCity: '',
    billingState: '',
    billingCountry: 'US',
    billingZip: '',
    billingSameAsShipping: true,
    brand: offer.brand,
    isTermsAgreed: true,
    productId: initialProduct.id,
    productQty: initialProduct.quantity,
    productName: initialProduct.name,
    productPrice: initialProduct.price,
    agreeSms: false,
    fbc: getFbcValue(queryParams),
    fbp: Cookies.get('_fbp'),
    custom_fields: getCustomFields(),
    orderTotal: initialProduct.price * 1 + initialProduct.shippingPrice * 1,
    campaignId: offer.campaignId,
    shippingId: initialProduct.shippingId || '',
    shippingPrice: initialProduct.shippingPrice || '',
    offers: [
      {
        product_id: initialProduct.id,
        offer_id: initialProduct.offerId,
        billing_model_id: initialProduct.billingModelId,
        price: initialProduct.price,
        productQty: initialProduct.quantity,
        productName: initialProduct.name,
        isMainProduct: true,
      },
    ],
  });

  async function getBanners() {
    try {
      const response = await api.get('/banners');
      if (response.data) {
        setBanners(response.data);
      }
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  useEffect(() => {
    if (!offer.isBannerDisabled) {
      getBanners();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filteredProducts = filterProducts(mergedProductsData);
    const newSelectedProduct =
      filteredProducts.find(
        (item) =>
          item.quantity === selectedProduct?.quantity &&
          item.color === selectedColor?.name &&
          (someProductIsSubscription
            ? item.billingModelId ===
              (selectedOfferType === 'subscription' ? selectedProduct?.billingModelId : '2')
            : true)
      ) || filteredProducts[0];

    if (
      newSelectedProduct &&
      newSelectedProduct?.id !== selectedProduct?.id &&
      newSelectedProduct.soldOut !== '1'
    ) {
      setSelectedProduct(newSelectedProduct);
    } else if (newSelectedProduct?.soldOut === '1') {
      setSelectedProduct(null);
    }
  }, [selectedOfferType, selectedColor]);

  useEffect(() => {
    const stickyOffer = {
      billing_model_id: selectedProduct?.billingModelId,
      offer_id: selectedProduct?.offerId,
      product_id: selectedProduct?.id,
      price: selectedProduct?.price,
      productQty: selectedProduct?.quantity,
      productName: selectedProduct?.name,
      isMainProduct: true,
    };

    const currentOffers = state.offers || [];
    const newOffers = currentOffers.filter((offer) => !offer.isMainProduct);
    newOffers.push(stickyOffer);

    const offersTotal = newOffers.reduce((total, offer) => total + offer.price * 1, 0);
    const orderTotal = offersTotal + selectedProduct?.shippingPrice * 1;

    setState({
      ...state,
      orderTotal,
      offers: newOffers,
      shippingId: selectedProduct?.shippingId,
      shippingPrice: selectedProduct?.shippingPrice,
      productId: selectedProduct?.id,
      productQty: selectedProduct?.quantity,
      productName: selectedProduct?.name,
      productPrice: selectedProduct?.price,
    });
  }, [selectedProduct]);

  useEffect(() => {
    if (crossSells.length > 0) {
      const preSelected = crossSells.filter((item) => item.preselectedOffers.some((o) => o.id === offer._id));

      if (preSelected.length > 0) {
        const crossSellOffers = [];

        preSelected.forEach((item) => {
          let itemToAdd = item;

          if (item.variants && item.variants.length > 0) {
            itemToAdd = item.variants[0];
          }

          crossSellOffers.push({
            billing_model_id: itemToAdd.productBillingModelId,
            offer_id: itemToAdd.productOfferId,
            product_id: itemToAdd.productId,
            price: itemToAdd.price,
            productName: itemToAdd.productName || itemToAdd.name,
            productQty: '1',
            isCrossSell: true,
          });
        });

        setState((prevState) => {
          const currentOffers = prevState.offers || [];
          const offersWithoutPreselectedCrossSells = currentOffers.filter(
            (offer) => !(offer.isCrossSell && offer.preselected)
          );
          const newOffers = [...offersWithoutPreselectedCrossSells, ...crossSellOffers];

          const offersTotal = newOffers.reduce((total, offer) => total + offer.price * 1, 0);
          const orderTotal = offersTotal + selectedProduct?.shippingPrice * 1;

          return {
            ...prevState,
            orderTotal,
            offers: newOffers,
          };
        });
      }
    }
  }, [crossSells]);

  useEffect(() => {
    const products = mergedProductsData;
    let defaultProduct = products[0];

    if (products?.length > 0) {
      setSelectedProduct(defaultProduct);
    }

    if (defaultVariantId) {
      defaultProduct = products.find((item) => item.id === defaultVariantId);
      defaultProduct && setSelectedProduct(defaultProduct);
    }

    if (defaultVariantId && !selectedColor && defaultProduct) {
      handleColorSelect({
        name: defaultProduct.color,
        imageSrc: defaultProduct.colorImage || defaultProduct.colorimage,
      });
    }

    if (products && defaultProduct) {
      setSelectedColor(
        selectedColor
          ? selectedColor
          : {
              name: defaultProduct.color,
              imageSrc: defaultProduct.colorImage || defaultProduct.colorimage,
            }
      );
    }
  }, [offer.productsData]);

  useEffect(() => {
    const anotherProductWithSameQuantity = mergedProductsData.find(
      (item) => item.quantity === selectedProduct?.quantity && item.id !== selectedProduct?.id
    );

    if (anotherProductWithSameQuantity) {
      setSelectedProduct(anotherProductWithSameQuantity);
    }
  }, [selectedOfferType]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/braintree/token?merchantAccountId=${offer.payPalMerchantAccountId}`);
      const clientToken = data.clientToken;
      setClientToken(clientToken);
    })();
  }, []);

  useEffect(() => {
    // Fetch reviews when the component mounts
    const fetchReviews = async () => {
      try {
        const response = await api.get('/reviews?offerId=' + offer._id);
        setReviews(response.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, []);

  useEffect(() => {
    if (state.shippingCountry === 'US' || state.shippingCountry === 'CA') {
      setState({ ...state, shippingState: 'AL' });
    } else {
      setState({ ...state, shippingState: '' });
    }
  }, [state.shippingCountry]);

  // Helpers

  function updateOffers(item, meta) {
    const newOffers = [...state.offers];
    const existingItem = state.offers.find((o) => o.product_id * 1 === item.productId * 1);

    if (existingItem) {
      newOffers.splice(newOffers.indexOf(existingItem), 1);
    } else {
      newOffers.push({
        billing_model_id: item.productBillingModelId,
        offer_id: item.productOfferId,
        product_id: item.productId,
        price: item.price,
        productName: item.productName || item.name,
        ...meta,
      });
    }

    setState({ ...state, offers: newOffers });
  }

  function filterProductsByColor(items) {
    if (!selectedColor) {
      return items;
    }

    return items.filter((item) => item.color === selectedColor.name);
  }

  function filterByOfferType(items) {
    if (someProductIsSubscription) {
      return items.filter((item) => {
        if (selectedOfferType === 'subscription') {
          return item.billingModelId !== '2';
        }

        return item.billingModelId === '2';
      });
    }

    return items;
  }

  function filterProducts(items) {
    const filteredByColor = filterProductsByColor(items);
    return filterByOfferType(filteredByColor);
  }

  function getSavings() {
    const qty = selectedProduct?.quantity;

    if (!qty) {
      return 0;
    }

    const oneTimeProduct = mergedProductsData.find(
      (item) => item.billingModelId === '2' && item.quantity === qty
    );
    const subscriptionProduct = mergedProductsData.find(
      (item) => item.billingModelId !== '2' && item.quantity === qty
    );

    return getPriceWithCurrency(
      oneTimeProduct.price * 1 + oneTimeProduct.shippingPrice * 1 - subscriptionProduct.price * 1,
      offer?.locale,
      offer?.currency
    );
  }

  // Handlers

  function handleColorSelect(color) {
    setSelectedColor(color);
    const newProduct = mergedProductsData.find(
      (item) => item.color === color.name && item.quantity === selectedProduct?.quantity
    );

    if (newProduct?.soldOut === '1') {
      setSelectedProduct(null);
    }

    setSelectedProduct(newProduct);
  }

  function handleFieldBlur(fieldName, value) {
    const errorMessage = validateField(fieldName, state[fieldName], state.shippingCountry);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  }

  function handleFieldChange(e) {
    const { name, value } = e.target;
    if (name === 'phone') {
      const prefix =
        state.shippingCountry === 'GB'
          ? '+44'
          : state.shippingCountry === 'US' || state.shippingCountry === 'CA'
          ? '+1'
          : '';
      const phoneNumber = value.replace(/\D/g, ''); // Remove non-numeric characters
      setState((prevState) => ({
        ...prevState,
        [name]: `${prefix}${phoneNumber}`, // Store with prefix in state
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }

  function handleCrossSellChange(crossSell) {
    updateOffers(crossSell, { isCrossSell: true });
  }

  function handleExpressApprove(data) {
    handleCreateOrder(data);
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    const validationErrors = validateForm(state, state.shippingCountry);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      handleCreateOrder(state);
    } else {
      const firstErrorField = Object.keys(validationErrors)[0];
      const fieldElement = document.querySelector(`[name="${firstErrorField}"]`);
      if (fieldElement) {
        fieldElement.focus();
      }
    }
  }

  function handleCreateOrder(data) {
    const stickyOffer = {
      billing_model_id: selectedProduct?.billingModelId,
      offer_id: selectedProduct?.offerId,
      product_id: selectedProduct?.id,
      price: selectedProduct?.price,
      productQty: selectedProduct?.quantity,
      productName: selectedProduct?.name,
      isMainProduct: true,
    };

    const currentOffers = state.offers || [];
    const newOffers = currentOffers.filter((offer) => !offer.isMainProduct);
    newOffers.push(stickyOffer);

    const offersTotal = newOffers.reduce((total, offer) => total + offer.price * 1, 0);
    const orderTotal = offersTotal + selectedProduct?.shippingPrice * 1;
    const expirationDate = `${state.expirationMonth}${state.expirationYear.slice(-2)}`;

    const orderData = {
      ...data,
      expirationDate,
      orderTotal,
      offers: newOffers,
      shippingId: selectedProduct?.shippingId,
      shippingPrice: selectedProduct?.shippingPrice,
      productId: selectedProduct?.id,
      productQty: selectedProduct?.quantity,
      productName: selectedProduct?.name,
      productPrice: selectedProduct?.price,
    };

    if (!selectedProduct) {
      setErrors({ ...errors, product: 'Please select a product' });
      return;
    } else {
      setErrors({ ...errors, product: null });
    }

    onCreateOrder(orderData);
  }

  function renderStateSelect() {
    // Remove restricted states from states if SolVolt offer
    if (queryParams.offerid === '66461c98e81a190011fb3092') {
      const restrictedStates = ['CT', 'HI', 'IL', 'IN', 'MA', 'MI', 'NC', 'RI', 'VI', 'WA', 'DC'];

      for (const state in states['US']) {
        if (restrictedStates.includes(state)) {
          delete states['US'][state];
        }
      }
    }

    if (state.shippingCountry === 'US' || state.shippingCountry === 'CA') {
      return (
        <div className={styles.formElement}>
          <div className={`${styles.formField} ${state.shippingState && styles.formFieldActive}`}>
            <label htmlFor='shippingState'>State/Province</label>
            <select
              required
              name='shippingState'
              className={styles.selectField}
              value={state.shippingState}
              onChange={(e) => {
                setState({ ...state, shippingState: e.target.value });
                setErrors((prevErrors) => ({ ...prevErrors, shippingState: null }));
              }}
              onBlur={() => handleFieldBlur('shippingState')}
            >
              {Object.entries(states[state.shippingCountry]).map(([code, name], index) => {
                return (
                  <option key={index} value={code}>
                    {name}
                  </option>
                );
              })}
            </select>
            {errors.shippingState && <div className={styles.errorMessage}>{errors.shippingState}</div>}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.formElement}>
        <div className={styles.formField}>
          <label htmlFor='shippingState'>County/District/Region</label>
          <input
            placeholder='County/District/Region'
            type='text'
            name='shippingState'
            className={errors.shippingState ? styles.errorInput : styles.inputField}
            value={state.shippingState}
            onChange={(e) => {
              setState({ ...state, shippingState: e.target.value });
              setErrors((prevErrors) => ({ ...prevErrors, shippingState: null }));
            }}
            onBlur={() => handleFieldBlur('shippingState')}
          />
          {errors.shippingState && <div className={styles.errorMessage}>{errors.shippingState}</div>}
        </div>
      </div>
    );
  }

  function renderOrderSummary() {
    return (
      <div className={styles.orderSummary}>
        <div className={styles.orderBox}>
          <div className={styles.orderLeft}>
            <div className={styles.orderLeftProductBox}>
              <img src={selectedProduct?.image} width='240' height='202' alt='img' />
              <p className={styles.orderProductCount}>{selectedProduct?.quantity}</p>
            </div>
            <div className={styles.orderProductInfo}>
              <p className={styles.orderProductInfoText1}>{offer.productName}</p>
              <p className={styles.orderProductInfoText2}>{offer.productDescription}</p>
            </div>
          </div>
          <div className={styles.orderProductPrice}>
            <p className={styles.orderProductCutPrice}>
              <span>{getPriceWithCurrency(selectedProduct?.oldPrice, offer?.locale, offer?.currency)}</span>
            </p>
            <p className={styles.orderProductPriceText}>
              {getPriceWithCurrency(selectedProduct?.price, offer?.locale, offer?.currency)}
            </p>
          </div>
        </div>

        <div className={styles.cartBox}>
          <table className={styles.cartTable} cellPadding='0' cellSpacing='0'>
            <tbody>
              <tr>
                <td align='left'>Subtotal</td>
                <td align='right'>
                  <span>{getPriceWithCurrency(state.orderTotal, offer?.locale, offer?.currency)}</span>
                </td>
              </tr>
            </tbody>
          </table>

          <table className={styles.cartTable} cellPadding='0' cellSpacing='0'>
            <tbody>
              <tr>
                <td align='left'>Shipping</td>
                <td align='right'>
                  <span>
                    {selectedProduct?.shippingPrice > 0
                      ? getPriceWithCurrency(selectedProduct?.shippingPrice, offer?.locale, offer?.currency)
                      : 'FREE'}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {/* <table className={styles.cartTable} cellPadding='0' cellSpacing='0'>
            <tbody>
              <tr>
                <td align='left'>
                  Estimated taxes
                  <a href='#' className={styles.tableQuestionIcon}>
                    <img src={questionIcn} width='34' height='34' alt='icn' />
                    <div className={styles.toolTips}>
                      The final tax and total will be confirmed by email or text after you place your order.
                    </div>
                  </a>
                </td>
                <td align='right'>
                  <span>$0.00</span>
                </td>
              </tr>
            </tbody>
          </table> */}
        </div>

        <table className={styles.cartTable} cellPadding='0' cellSpacing='0'>
          <tbody>
            <tr>
              <td align='left' className={styles.totalTextLeft}>
                <strong>Total</strong>
              </td>
              <td align='right' className={styles.totalText}>
                <span>{getPriceWithCurrency(state.orderTotal, offer?.locale, offer?.currency)}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className={styles.shopifyTemplate}>
      <Banner banners={banners} discount={getMaxDiscount(offer)} />

      <section className={styles.checkoutBackground} style={{ overflow: 'hidden' }}>
        <div className={styles.container}>
          <div className={styles.checkoutLeft}>
            <div className={styles.logoBox}>
              <img src={offer.logoSrc} className={styles.logo} width='292' height='50' alt='logo' />
              <p className={styles.secureLock}>
                <img src={secureLock} width='40' height='40' alt='lock' /> SECURE CHECKOUT
              </p>
            </div>

            <Timer />

            <form onSubmit={handleFormSubmit}>
              <div className={styles.contactInformation}>
                <div className={styles.headingBox}>
                  <p className={styles.checkoutHeading}>Select quantity</p>
                </div>

                <div className={styles.packageBlock}>
                  {someProductIsSubscription && (
                    <div className={styles.switchOptionBox} id='slct-pkg'>
                      <label
                        className={`${styles.switchOption} ${styles.otp} ${
                          selectedOfferType === 'one-time' && styles.active
                        }`}
                        onClick={() => setSelectedOfferType('one-time')}
                      >
                        One-Time <br />
                        Purchase
                      </label>
                      <label
                        className={`${styles.switchOption} ${styles.Subscribe} ${
                          selectedOfferType === 'subscription' && styles.active
                        }`}
                        onClick={() => setSelectedOfferType('subscription')}
                      >
                        Subscribe &<p className={styles.subscribeSaveText}>save extra {getSavings()}</p>
                        <p className={styles.subscribeShipText}>+FREE SHIPPING ALWAYS</p>
                      </label>
                    </div>
                  )}

                  <div className={`${styles.packageSection}`}>
                    {filterProducts(mergedProductsData).map((product) => (
                      <div
                        key={product.id}
                        className={`${styles.package} ${selectedProduct?.id === product.id && styles.active}`}
                        onClick={() => setSelectedProduct(product)}
                      >
                        {(product.bestValue || product.mostPopular || product.soldOut) && (
                          <p
                            className={`${styles.packageOfferName} ${
                              product.bestValue && styles.mostPopular
                            }`}
                          >
                            {product.bestValue
                              ? 'Best Value'
                              : product.mostPopular
                              ? 'Most Popular'
                              : 'Sold Out'}
                          </p>
                        )}
                        <div className={styles.packageProduct}>
                          <img
                            src={product.image}
                            width='240'
                            height='202'
                            alt='prod'
                            className={styles.packageProductImage}
                          />
                        </div>
                        <div className={styles.packageProductInfo}>
                          <p className={styles.packageProductInfoText1}>{product.quantity}X</p>
                          {product.subtitle && <p className={styles.productSubtitle}>{product.subtitle}</p>}
                          <p
                            className={styles.packageProductInfoQuantity}
                            dangerouslySetInnerHTML={{
                              __html: makePlural(offer.productName, product.quantity * 1),
                            }}
                          ></p>
                          <p className={styles.packageProductInfoCutPrice}>
                            {getPriceWithCurrency(product.oldPrice, offer?.locale, offer?.currency)}
                          </p>
                          <p className={styles.packageProductInfoOfferPrice}>
                            {getPriceWithCurrency(product.price, offer?.locale, offer?.currency)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Color Select */}
                  {selectedColor && selectedColor.name && (
                    <ColorSelect
                      colors={mapColorsFromProducts(mergedProductsData)}
                      onChange={handleColorSelect}
                      selectedColor={selectedColor}
                      defaultProduct={mergedProductsData.find((item) => item.id === defaultVariantId)}
                    />
                  )}

                  {crossSells.length > 0 && (
                    <CrossSells
                      offer={offer}
                      offers={state.offers}
                      crossSells={crossSells}
                      onChange={handleCrossSellChange}
                    />
                  )}
                </div>
              </div>

              <div className={`${styles.summaryDiv} ${styles.forTablet} ${styles.forMobile}`}>
                <div className={styles.summaryToggleMobile}>
                  <div
                    className={styles.summaryToggle}
                    onClick={() => setShowOrderSummary(!showOrderSummary)}
                  >
                    <p>
                      <span className={styles.summaryToggleText}>
                        Order Summary
                        <svg
                          width='11'
                          height='6'
                          xmlns='http://www.w3.org/2000/svg'
                          className={styles.orderSummaryToggleDropdown}
                          fill='#0362bb'
                          style={{ transform: showOrderSummary ? 'rotate(180deg)' : 'rotate(0deg)' }}
                        >
                          <path d='M.504 1.813l4.358 3.845.496.438.496-.438 4.642-4.096L9.504.438 4.862 4.534h.992L1.496.69.504 1.812z'></path>
                        </svg>
                      </span>
                    </p>
                    <p className={styles.togglePrice}>
                      <strong>
                        {getPriceWithCurrency(state.orderTotal, offer?.locale, offer?.currency)}
                      </strong>
                    </p>
                  </div>
                </div>
                {showOrderSummary && renderOrderSummary()}
              </div>

              <div className={styles.expressCheckoutBox}>
                {/* Express Checkout */}
                {clientToken && showPayPal && (
                  <div className={styles.expressCheckout}>
                    <PayPalScriptProvider
                      options={{
                        vault: true,
                        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                        components: 'buttons',
                        dataClientToken: clientToken,
                      }}
                    >
                      <PayPalButtons
                        offer={offer}
                        orderData={state}
                        checkoutTotal={state.orderTotal}
                        onApprove={handleExpressApprove}
                      />
                    </PayPalScriptProvider>
                  </div>
                )}

                <p className={styles.expressCheckoutText2}>
                  By continuing with your payment, you agree to the future charges listed on this page and the
                  cancellation policy.
                </p>
              </div>

              <p className={styles.orText}>
                <span>OR</span>
              </p>

              <div className={styles.contactInformation}>
                <div className={styles.headingBox}>
                  <p className={styles.checkoutHeading}>Contact information</p>
                </div>

                <div className={styles.formElement}>
                  <div className={`${styles.formField} ${state.email && styles.formFieldActive}`}>
                    <label htmlFor='email'>Email</label>
                    <input
                      required
                      type='email'
                      className={styles.inputField}
                      placeholder='Email'
                      id='email'
                      name='email'
                      value={state.email}
                      onChange={handleFieldChange}
                      onBlur={() => handleFieldBlur('email')}
                    />
                    {errors.email && <div className={styles.errorMessage}>{errors.email}</div>}
                  </div>
                </div>
              </div>

              <div className={styles.deliveryInformation}>
                <div className={styles.headingBox}>
                  <p className={styles.checkoutHeading}>Delivery</p>
                </div>

                <div className={styles.formElement}>
                  <div className={`${styles.formField} ${state.shippingCountry && styles.formFieldActive}`}>
                    <label htmlFor='shippingCountry'>Country/ Region</label>
                    <select
                      required
                      name='shippingCountry'
                      className={styles.selectField}
                      id='shippingCountry'
                      value={state.shippingCountry}
                      onChange={handleFieldChange}
                      onBlur={() => handleFieldBlur('shippingCountry')}
                    >
                      {offer.shippingCountries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {errors.shippingCountry && (
                      <div className={styles.errorMessage}>{errors.shippingCountry}</div>
                    )}
                  </div>
                </div>

                <div className={styles.formElement}>
                  <div className={`${styles.formField} ${state.firstName && styles.formFieldActive}`}>
                    <label htmlFor='firstName'>First Name</label>
                    <input
                      required
                      type='text'
                      className={styles.inputField}
                      placeholder='First Name'
                      id='firstName'
                      name='firstName'
                      value={state.firstName}
                      onChange={handleFieldChange}
                      onBlur={() => handleFieldBlur('firstName')}
                    />
                    {errors.firstName && <div className={styles.errorMessage}>{errors.firstName}</div>}
                  </div>
                </div>

                <div className={styles.formElement}>
                  <div className={`${styles.formField} ${state.lastName && styles.formFieldActive}`}>
                    <label htmlFor='lastName'>Last Name</label>
                    <input
                      required
                      type='text'
                      className={styles.inputField}
                      placeholder='Last Name'
                      id='lastName'
                      name='lastName'
                      value={state.lastName}
                      onChange={handleFieldChange}
                      onBlur={() => handleFieldBlur('lastName')}
                    />
                    {errors.lastName && <div className={styles.errorMessage}>{errors.lastName}</div>}
                  </div>
                </div>

                <div className={styles.formElement}>
                  <div className={`${styles.formField} ${state.shippingAddress1 && styles.formFieldActive}`}>
                    <label htmlFor='shippingAddress1'>Address</label>
                    <input
                      required
                      type='text'
                      className={styles.inputField}
                      placeholder='Address'
                      id='shippingAddress1'
                      name='shippingAddress1'
                      value={state.shippingAddress1}
                      onChange={handleFieldChange}
                      onBlur={() => handleFieldBlur('shippingAddress1')}
                    />
                    {errors.shippingAddress1 && (
                      <div className={styles.errorMessage}>{errors.shippingAddress1}</div>
                    )}
                  </div>
                </div>

                <div className={styles.formElement}>
                  <div className={`${styles.formField} ${state.shippingCity && styles.formFieldActive}`}>
                    <label htmlFor='shippingCity'>City</label>
                    <input
                      required
                      type='text'
                      className={styles.inputField}
                      placeholder='City'
                      id='shippingCity'
                      name='shippingCity'
                      value={state.shippingCity}
                      onChange={handleFieldChange}
                      onBlur={() => handleFieldBlur('shippingCity')}
                    />
                    {errors.shippingCity && <div className={styles.errorMessage}>{errors.shippingCity}</div>}
                  </div>
                </div>

                {renderStateSelect()}

                <div className={styles.formElement}>
                  <div className={`${styles.formField} ${state.shippingZip && styles.formFieldActive}`}>
                    <label htmlFor='shippingZip'>Zip Code</label>
                    <input
                      required
                      type='text'
                      className={styles.inputField}
                      placeholder='Zip Code'
                      id='shippingZip'
                      name='shippingZip'
                      value={state.shippingZip}
                      onChange={handleFieldChange}
                      onBlur={() => handleFieldBlur('shippingZip')}
                    />
                    {errors.shippingZip && <div className={styles.errorMessage}>{errors.shippingZip}</div>}
                  </div>
                </div>

                <div className={styles.formElement}>
                  <div className={`${styles.formField} ${state.phone && styles.formFieldActive}`}>
                    <label htmlFor='phone'>Phone</label>
                    <input
                      required
                      type='tel'
                      className={styles.inputField}
                      placeholder='Phone'
                      id='phone'
                      name='phone'
                      value={state.phone.replace(/^\+44|^\+1/, '')} // Display without the prefix
                      onChange={handleFieldChange}
                      onBlur={() => handleFieldBlur('phone')}
                    />
                    <a href='#' className={styles.callQuestion}>
                      <img
                        src={questionIcn}
                        width='34'
                        height='34'
                        alt='icn'
                        className={styles.questionIcon}
                      />
                      <div className={styles.tooltip}>In case we need to contact you about your order</div>
                    </a>
                    {state.shippingCountry === 'GB' && (
                      <div className={styles.inputMessage}>
                        Enter your phone number without the leading <b>0</b>. For example, if your number is{' '}
                        <b>07700123456</b>, simply type <b>7700123456</b>. The international format is
                        automatically applied.
                      </div>
                    )}
                    {errors.phone && <div className={styles.errorMessage}>{errors.phone}</div>}
                  </div>
                </div>
              </div>

              <div className={styles.shippingInformation}>
                <div className={styles.headingBox}>
                  <p className={styles.checkoutHeading}>Payment</p>
                </div>

                <div className={styles.paymentOptionBox}>
                  <div className={`${styles.paymentCardsBox} ${styles.cardPayOpt}`}>
                    <label className={styles.paymentButton}>Credit card</label>
                    <img src={paymentCards} width='112' height='26' alt='card' />
                  </div>

                  <div className={styles.paymentFieldsBox} id='CardPayment'>
                    <div
                      className={`${styles.formField} ${state.creditCardNumber && styles.formFieldActive}`}
                    >
                      <label htmlFor='creditCardNumber'>Card Number*</label>
                      <input
                        required
                        type='text'
                        className={styles.inputField}
                        placeholder='Card Number*'
                        id='creditCardNumber'
                        name='creditCardNumber'
                        maxLength='19'
                        value={state.creditCardNumber}
                        onChange={handleFieldChange}
                        onBlur={() => handleFieldBlur('creditCardNumber')}
                      />
                      {errors.creditCardNumber && (
                        <div className={styles.errorMessage}>{errors.creditCardNumber}</div>
                      )}
                    </div>

                    <div className={styles.formElement}>
                      <div
                        className={`${styles.formField} ${styles.paymentHalfField} ${
                          state.expirationMonth && styles.formFieldActive
                        }`}
                      >
                        <label htmlFor='expirationMonth'>Expiry Month</label>
                        <select
                          required
                          className={styles.selectField}
                          id='expirationMonth'
                          name='expirationMonth'
                          value={state.expirationMonth}
                          onChange={handleFieldChange}
                          onBlur={() => handleFieldBlur('expirationMonth')}
                        >
                          <option value=''>Month</option>
                          <option value='01'>01 - January</option>
                          <option value='02'>02 - February</option>
                          <option value='03'>03 - March</option>
                          <option value='04'>04 - April</option>
                          <option value='05'>05 - May</option>
                          <option value='06'>06 - June</option>
                          <option value='07'>07 - July</option>
                          <option value='08'>08 - August</option>
                          <option value='09'>09 - September</option>
                          <option value='10'>10 - October</option>
                          <option value='11'>11 - November</option>
                          <option value='12'>12 - December</option>
                        </select>
                        {errors.expirationMonth && (
                          <div className={styles.errorMessage}>{errors.expirationMonth}</div>
                        )}
                      </div>

                      <div
                        className={`${styles.formField} ${styles.paymentHalfField} ${
                          state.expirationYear && styles.formFieldActive
                        }`}
                      >
                        <label htmlFor='expirationYear'>Expiry Year</label>
                        <select
                          required
                          className={styles.selectField}
                          id='expirationYear'
                          name='expirationYear'
                          value={state.expirationYear}
                          onChange={handleFieldChange}
                          onBlur={() => handleFieldBlur('expirationYear')}
                        >
                          <option value=''>Year</option>
                          {Array.from({ length: 20 }, (_, i) => {
                            const year = new Date().getFullYear() + i;
                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                        {errors.expirationYear && (
                          <div className={styles.errorMessage}>{errors.expirationYear}</div>
                        )}
                      </div>
                    </div>

                    <div
                      className={`${styles.formField} ${styles.paymentHalfField} ${
                        state.CVV && styles.formFieldActive
                      }`}
                    >
                      <label htmlFor='CVV'>CVV</label>
                      <input
                        required
                        type='text'
                        className={styles.inputField}
                        placeholder='Security Code*'
                        maxLength='4'
                        id='CVV'
                        name='CVV'
                        value={state.CVV}
                        onChange={handleFieldChange}
                        onBlur={() => handleFieldBlur('CVV')}
                      />
                      {errors.CVV && <div className={styles.errorMessage}>{errors.CVV}</div>}
                    </div>

                    <div className={styles.billingSame}>
                      <label className={`${styles.labelCheckbox} `}>
                        <input
                          type='checkbox'
                          id='billingSameAsShipping'
                          name='billingSameAsShipping'
                          className={styles.allCheckbox}
                          checked={state.billingSameAsShipping}
                          onChange={(e) => setState({ ...state, billingSameAsShipping: e.target.checked })}
                        />
                        Shipping address same as billing address
                      </label>

                      {!state.billingSameAsShipping && (
                        <div className={styles.paymentFieldsBox} style={{ padding: '15px 0' }}>
                          <div className={styles.formElement}>
                            <div
                              className={`${styles.formField} ${
                                state.billingCountry && styles.formFieldActive
                              }`}
                            >
                              <label htmlFor='billingCountry'>Select country</label>
                              <select
                                name='billingCountry'
                                className={styles.selectField}
                                id='billingCountry'
                                value={state.billingCountry}
                                onChange={handleFieldChange}
                                onBlur={() => handleFieldBlur('billingCountry')}
                              >
                                {offer.shippingCountries.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                              {errors.billingCountry && (
                                <div className={styles.errorMessage}>{errors.billingCountry}</div>
                              )}
                            </div>
                          </div>

                          <div className={styles.formElement}>
                            <div
                              className={`${styles.formField} ${
                                state.billingFirstName && styles.formFieldActive
                              }`}
                            >
                              <label htmlFor='billingFirstName'>First Name</label>
                              <input
                                type='text'
                                className={styles.inputField}
                                placeholder='First Name'
                                id='billingFirstName'
                                name='billingFirstName'
                                value={state.billingFirstName}
                                onChange={handleFieldChange}
                                onBlur={() => handleFieldBlur('billingFirstName')}
                              />
                              {errors.billingFirstName && (
                                <div className={styles.errorMessage}>{errors.billingFirstName}</div>
                              )}
                            </div>
                          </div>

                          <div className={styles.formElement}>
                            <div
                              className={`${styles.formField} ${
                                state.billingLastName && styles.formFieldActive
                              }`}
                            >
                              <label htmlFor='billingLastName'>Last Name</label>
                              <input
                                type='text'
                                className={styles.inputField}
                                placeholder='Last Name'
                                id='billingLastName'
                                name='billingLastName'
                                value={state.billingLastName}
                                onChange={handleFieldChange}
                                onBlur={() => handleFieldBlur('billingLastName')}
                              />
                              {errors.billingLastName && (
                                <div className={styles.errorMessage}>{errors.billingLastName}</div>
                              )}
                            </div>
                          </div>

                          <div className={styles.formElement}>
                            <div
                              className={`${styles.formField} ${
                                state.billingAddress1 && styles.formFieldActive
                              }`}
                            >
                              <label htmlFor='billingAddress1'>Address</label>
                              <input
                                type='text'
                                className={styles.inputField}
                                placeholder='Address'
                                id='billingAddress1'
                                name='billingAddress1'
                                value={state.billingAddress1}
                                onChange={handleFieldChange}
                                onBlur={() => handleFieldBlur('billingAddress1')}
                              />
                              {errors.billingAddress1 && (
                                <div className={styles.errorMessage}>{errors.billingAddress1}</div>
                              )}
                            </div>
                          </div>

                          <div className={styles.formElement}>
                            <div
                              className={`${styles.formField} ${state.billingCity && styles.formFieldActive}`}
                            >
                              <label htmlFor='billingCity'>City</label>
                              <input
                                type='text'
                                className={styles.inputField}
                                placeholder='City'
                                id='billingCity'
                                name='billingCity'
                                value={state.billingCity}
                                onChange={handleFieldChange}
                                onBlur={() => handleFieldBlur('billingCity')}
                              />
                              {errors.billingCity && (
                                <div className={styles.errorMessage}>{errors.billingCity}</div>
                              )}
                            </div>
                          </div>

                          {renderStateSelect()}

                          <div className={styles.formElement}>
                            <div
                              className={`${styles.formField} ${state.billingZip && styles.formFieldActive}`}
                            >
                              <label htmlFor='billingZip'>Zip Code</label>
                              <input
                                type='tel'
                                className={styles.inputField}
                                placeholder='Zip Code'
                                id='billingZip'
                                name='billingZip'
                                value={state.billingZip}
                                onChange={handleFieldChange}
                                onBlur={() => handleFieldBlur('billingZip')}
                              />
                              {errors.billingZip && (
                                <div className={styles.errorMessage}>{errors.billingZip}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <label className={styles.termsConditionsRow}>
                <input
                  type='checkbox'
                  className={styles.updateDiscount}
                  name='agreeSms'
                  checked={state.agreeSms}
                  onChange={(e) => setState({ ...state, agreeSms: e.target.checked })}
                />
                <div className={styles.termsConditionsContent}>
                  <p className={styles.termsConditionsText}>
                    Text Me Shipping Updates and Exclusive Discounts
                  </p>
                </div>
              </label>

              <p
                className={`${styles.termsConditionsText2} ${styles.discountTermText}`}
                style={{ display: 'none' }}
              >
                <i>
                  By clicking this checkbox, you agree to receive marketing text messages from our company at
                  the number provided. Consent is not a condition of purchase. Message and data rates may
                  apply. Message frequency varies. Reply HELP for help or STOP to cancel. View our Privacy
                  Policy and Terms of Service.
                </i>
              </p>

              <p className={styles.termsConditionsText2}>
                By placing your order, you agree to <span onClick={onTermsClick}>terms &amp; conditions</span>{' '}
                of service.
              </p>

              <div className={styles.submitContainer}>
                <button type='submit' className={styles.submitButton} disabled={isLoading}>
                  <img src={lock2} width='20' height='27' alt='icn' />
                  complete purchase
                </button>
              </div>

              {declineError && (
                <div className={styles.declineError}>
                  <p>{declineError}</p>
                </div>
              )}

              <img
                src={saveCheckoutImage}
                width='444'
                height='102'
                alt='img'
                className={styles.saveCheckoutImage}
              />
            </form>
          </div>

          <div className={styles.checkoutRight}>
            <div className={styles.forDesktop}>{renderOrderSummary()}</div>

            <p className={styles.checkoutReviewHeading}>
              <span>Why Choose {offer.brand}</span>
            </p>
            <div className={styles.chooseRow}>
              {offer.benefitsWithIcons.map((benefit, index) => (
                <div className={styles.chooseColumn} key={index}>
                  <img src={benefit.icon} width='64' height='64' alt='icn' className={styles.chooseIcon} />
                  <p className={styles.chooseColumnText}>{benefit.name}</p>
                </div>
              ))}
            </div>

            <div className={styles.guaranteeBox}>
              <div className={styles.guaranteeHeading}>
                <div className={styles.guaranteeSealBox}>
                  <img src={guaranteeSeal} width='112' height='110' alt='seal' />
                </div>
                <div className={styles.guaranteeHeadingRight}>
                  <p className={styles.guaranteeHeadingText}>30 Day Money Back Guarantee</p>
                </div>
              </div>
              <div className={styles.guaranteeBottom}>
                <p className={styles.guaranteeText}>
                  We are sure you’re going to love {offer.brand}! But in case you're not satisfied, we are
                  offering you our 30-day money-back guarantee. Return your package for a full refund.
                </p>
              </div>
            </div>

            <p className={styles.checkoutReviewHeading}>
              <span>Trusted Customer Reviews</span>
            </p>

            <div className={styles.reviewBox}>
              {reviews.map((review, index) => (
                <div className={styles.reviewColumn} key={index}>
                  <p className={styles.reviewColumnText1}>{review.title}</p>
                  <img src={star} className={styles.reviewStar} width='186' height='35' alt='' />
                  <p className={styles.reviewColumnText2}>{review.text}</p>
                  <img src={review.imageSrc} width='76' height='73' className={styles.reviewImg} alt='' />
                  <div className={styles.verifyRow}>
                    <p className={styles.verifyShortName}>{review?.name?.replace('- ', '')[0]}</p>
                    <p className={styles.verifyName}>{review.name}</p>
                    <p className={styles.verifyText}>
                      <img src={verifyIcn} width='20' height='22' className={styles.verifyIcn} alt='' />{' '}
                      Verified Purchase
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
