/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useContext } from 'react';
import Loading from '../../components/Loading/Loading';
import { Context as OrderContext } from '../../context/OrderContext';
import { PageView } from '../../utils/tracking';
import { buildQueryParams, getPriceWithCurrency } from '../../utils/helpers';
import './ThankYou.sass';

export default function ThankYouPage({ offer }) {
  const { company, brand } = offer;
  const queryParams = buildQueryParams(location, true);
  const {
    state: { order, loading, error },
    updateOrder,
    verifyOrder,
  } = useContext(OrderContext);

  const isPayPalOrder = order.creditCardType === 'paypal';
  const isStripeRedirect = window.location.href.includes('stripe');
  const internalOrderId = queryParams.internalorderid || localStorage.getItem('internalorderid') || '';
  const stickyOrderId = order?.processedResponseData?.order_id || '';
  const total = order?.processedResponseData?.orderTotal || '';
  const responseCode = queryParams.responseCode || order.processedResponseData?.response_code;
  const errorMessage = queryParams.errorMessage || order?.processedResponseData?.error_message;
  const declineReason = queryParams.declineReason || order?.processedResponseData?.decline_reason;
  const shippingPrice = order.shippingPrice * 1;
  const subtotal =
    order?.processedResponseData?.orderTotal -
      order?.processedResponseData?.orderSalesTaxAmount -
      shippingPrice || '';

  const tax = order?.processedResponseData?.orderSalesTaxAmount || '';

  window.dataLayer = window.dataLayer || [];

  // initial load - update and finish order
  useEffect(() => {
    const finishOrder = async () => {
      await updateOrder(internalOrderId, {
        flowIsComplete: true, // invokes processing of order
        three_d_redirect_url: window.location.href, // for 3DS authentication
        alt_pay_return_url: window.location.href, // for alt payment return redirect
      });
    };

    finishOrder();

    window.dataLayer.push({
      event: 'thankYouPageView',
    });
    PageView();
  }, []);

  // track order success or error
  useEffect(() => {
    if (responseCode === '100') {
      if (isPayPalOrder) {
        window.dataLayer.push({
          event: 'paypalOrderSuccess',
        });
      } else {
        window.dataLayer.push({
          event: 'paypalOrderError',
        });
      }
    }
  }, [responseCode]);

  // handle 3DS authentication
  useEffect(() => {
    if (responseCode === 10101 && !isStripeRedirect) {
      const handle3DSAuthentication = async () => {
        const response = await verifyOrder(internalOrderId);
        if (response) {
          document.open();
          document.write(response);
          document.close();
        }
      };

      handle3DSAuthentication();
    }
  }, [responseCode]);

  // update order after successful 3DS authentication
  useEffect(() => {
    if (isStripeRedirect && order?.processedResponseData) {
      const updateOrderResponseCode = async () => {
        await updateOrder(internalOrderId, {
          processedResponseData: {
            ...order.processedResponseData,
            response_code: responseCode,
            decline_reason: declineReason || '',
            error_message: errorMessage || '',
            error_found: queryParams.errorFound || '',
          },
        });
      };

      updateOrderResponseCode();
    }
  }, [responseCode, order?.processedResponseData?.response_code]);

  const goBackToCheckout = () => {
    window.location.href = `${localStorage.getItem('initialCheckoutUrl')}`;
  };

  if (loading) {
    return <Loading />;
  }

  if (error || errorMessage) {
    return (
      <main className='page-main'>
        <div className='container'>
          <div className='thank-you'>
            <div className='thank-you__title'>
              Sorry, we tried to process your order but something went wrong.
            </div>
            <div className='thank-you__text'>{errorMessage || error}</div>
            {localStorage.getItem('initialCheckoutUrl') && (
              <button onClick={goBackToCheckout} className='try-again'>
                <i className='fa fa-backward'></i> Go Back and Try Again
              </button>
            )}
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className='page-main'>
      <div className='container'>
        <div className='thank-you'>
          <div className='thank-you__title'>Thank you for shopping with {brand}</div>
          <div className='thank-you__text'>
            You will receive a confirmation email with a receipt of your recent purchase. If you have any
            questions or concerns, feel free to reach out to us at{' '}
            <span className='inline-block'>{company.email}</span> or by phone{' '}
            <span className='inline-block'>{company.phone}</span>
          </div>
          <div className='thank-you__order-line'>Your order number is - {stickyOrderId}</div>
          <div className='thank-you__order-line'>
            Your total is - {getPriceWithCurrency(total, offer?.locale, offer?.currency)}
          </div>
          <div className='thank-you__order-line'>
            Subtotal: {getPriceWithCurrency(subtotal, offer?.locale, offer?.currency)}
          </div>
          <div className='thank-you__order-line'>
            Sales Tax: {getPriceWithCurrency(tax, offer?.locale, offer?.currency)}
          </div>
          <div className='thank-you__order-line'>
            Shipping:{' '}
            {shippingPrice > 0 ? getPriceWithCurrency(shippingPrice, offer?.locale, offer?.currency) : 'FREE'}
          </div>
        </div>
      </div>
    </main>
  );
}
