import React, { useEffect, useState } from 'react';
import styles from './Shopify.module.css';
import timerIcn from './images/timer-icn.png';

const Timer = () => {
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 15,
    seconds: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        let { hours, minutes, seconds } = prevTime;

        if (seconds > 0) {
          seconds -= 1;
        } else if (minutes > 0) {
          seconds = 59;
          minutes -= 1;
        } else if (hours > 0) {
          seconds = 59;
          minutes = 59;
          hours -= 1;
        } else {
          clearInterval(intervalId); // Stop the countdown when it reaches zero
        }

        return { hours, minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={styles.viewBox}>
      <div className={styles.viewBoxColumn1}>
        <img src={timerIcn} width='52' height='60' alt='icn' />
      </div>
      <div className={styles.viewBoxColumn2}>
        Your Special Price Is Reserved For{' '}
        <span>
          {String(timeLeft.hours).padStart(2, '0')}:{String(timeLeft.minutes).padStart(2, '0')}:
          {String(timeLeft.seconds).padStart(2, '0')}
        </span>
      </div>
    </div>
  );
};

export default Timer;
